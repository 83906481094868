import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`大三暑假开始学习前端，2018年毕业，工作三年，希望自己坚持做出一个具有个人代表性的小项目`}</p>
    </blockquote>
    <p>{`编程语言主要是JavaScript，偶尔刷题用Java。`}</p>
    <p>{`目前朝着全栈方向发展，也有做过小型的node项目，在持续学习中。`}</p>
    <p>{`2022年小目标是把购买的课程学习完，继续沉淀自己，厚积而薄发。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      