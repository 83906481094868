import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Cai Qihao`}</h1>
    <p>{`你好，我是蔡启豪，三年前端老菜鸡，`}<a parentName="p" {...{
        "href": "https://blog.cqhpoldi.com/"
      }}>{`博客`}</a>{`
目前计划做一个可视化拖拽的数据大屏并且可以导出前端代码的项目(计划ing)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      